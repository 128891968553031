var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "profile-summary-container" },
    [
      _c("h1", { staticClass: "primary--text font-weight-bold" }, [
        _vm._v(" " + _vm._s(_vm.$t("profile.header.personalData")) + " ")
      ]),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "px-0 pr-md-2 mt-6 mb-10 ma-md-0",
              attrs: { cols: "12", md: "6" }
            },
            [
              _c(
                "v-card",
                { attrs: { tile: "", elevation: "0" } },
                [
                  _c("v-card-text", [
                    _vm.user.billingAddress.codInt
                      ? _c(
                          "div",
                          { staticClass: "zona-card-row" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _vm.user.billingAddress.codInt
                                      ? _c(
                                          "transition",
                                          { attrs: { name: "flip" } },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                key: _vm.flipped,
                                                staticClass:
                                                  "lighten-2 d-flex flex-column rounded card-big",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleCard()
                                                  }
                                                }
                                              },
                                              [
                                                _c("barcode", {
                                                  staticClass:
                                                    "align-self-center",
                                                  attrs: {
                                                    value:
                                                      _vm.user.billingAddress
                                                        .codInt,
                                                    options: {
                                                      format: "CODE39",
                                                      margin: 10,
                                                      height: 50,
                                                      width: 50,
                                                      font: "OCR-B",
                                                      fontSize: 0,
                                                      textMargin: -1
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "primary--text text--darken-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "zonaCardRow.header.cardLogged"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-block card-info text-h3 default--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.cart.user
                                                              .firstName
                                                          ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.cart.user
                                                                .lastName
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "zonaCardRow.text.cardNumber"
                                                          )
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-block card-info text-h3 default--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.user
                                                                .billingAddress
                                                                .codInt
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    !_vm.flipped
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "11px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Fai tap sulla carta per visualizzare il barcode"
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "my-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("profile.address.address")) +
                          ": " +
                          _vm._s(_vm.formattedAddress) +
                          " "
                      )
                    ]),
                    _c("div", { staticClass: "my-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("profile.contacts.mobilePhone")) +
                          ": " +
                          _vm._s(_vm.cart.user.phone) +
                          " "
                      )
                    ]),
                    _c("div", { staticClass: "my-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("profile.contacts.workPhone")) +
                          ": " +
                          _vm._s(_vm.cart.user.contact.workPhone) +
                          " "
                      )
                    ]),
                    _vm.user.email
                      ? _c("div", { staticClass: "my-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("profile.email.email")) +
                              ": " +
                              _vm._s(_vm.user.email) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    !_vm.user.email
                      ? _c(
                          "div",
                          { staticClass: "error--text font-weight-bold my-1" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("profile.email.noEmail")) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "my-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("profile.contacts.emailCertified")) +
                          ": " +
                          _vm._s(_vm.cart.user.person.emailCertified) +
                          " "
                      )
                    ]),
                    _c("div", { staticClass: "my-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("profile.contacts.vatSdiCode")) +
                          ": " +
                          _vm._s(_vm.cart.user.person.vatSdiCode) +
                          " "
                      )
                    ]),
                    _c("div", { staticClass: "mt-4" }, [
                      _c(
                        "a",
                        {
                          staticClass: "font-weight-bold",
                          attrs: { href: "/profile/profile-update" },
                          on: { click: _vm.clicked }
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.$t("profile.edit.data")))
                          ]),
                          _c(
                            "v-icon",
                            { attrs: { color: "primary", "x-small": "" } },
                            [_vm._v("$edit")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-0 pl-md-2", attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "primary--text",
                  attrs: { tile: "", elevation: "0" }
                },
                [
                  _c("v-card-text", [
                    _c("h3", { staticClass: "primary--text mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("profile.password")))
                    ]),
                    _c("div", [_vm._v("****************")]),
                    _c("div", { staticClass: "mt-4" }, [
                      _c(
                        "a",
                        {
                          staticClass: "font-weight-bold mt-4",
                          attrs: { href: "/profile/changepassword" }
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.$t("profile.edit.password")))
                          ]),
                          _c(
                            "v-icon",
                            { attrs: { color: "primary", "x-small": "" } },
                            [_vm._v("$edit")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.$platform_is_cordova && _vm.isBetaTester
            ? _c("v-col", { staticClass: "mt-8 development-link" }, [
                _c("a", { attrs: { href: _vm.developmentLink } }, [
                  _vm._v("Apri versione di test ")
                ])
              ])
            : _vm._e(),
          _vm.$platform_is_cordova && _vm.isBetaTester
            ? _c("v-col", { staticClass: "mt-8 development-link" }, [
                _c("a", { attrs: { href: _vm.testZonaLink } }, [
                  _vm._v("Apri versione di test GALA ")
                ])
              ])
            : _vm._e(),
          _vm.$platform_is_cordova
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "section default--text last links mt-5 text-center"
                  },
                  [
                    _c(
                      "i18n",
                      {
                        staticClass: "my-1 child pl-2 text-center",
                        attrs: { path: "footer.version", tag: "span" }
                      },
                      [_vm._v(" " + _vm._s(_vm.version) + " ")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }